$sidebar_bg: #4b5760;
$sidebar_bg_secondary: #556370;
$sidebar_text_primary: #fff;
$sidebar_text_secondary: darken($sidebar_text_primary, 20);
$sidebar_border: darken($sidebar_text_primary, 60);

.sidepanel-container {
  &.sticky {
    position: fixed;
    z-index: 100;
  }
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10;
  @include transition-property(width);
  @include transition-duration(0.01s);
  @include transition-delay(0.5s);
  &.open {
    width:100%;
    @include transition-duration(0.01s);
    @include transition-delay(0.0s);
    .sidepanel-overlay {
      height: 100%;
      @include transition-delay(0.0s);
      &:after {
        // opacity: 0.7;
      }
    }
    &.left .sidepanel-content {
      left: 0 !important;
    }
    &.right .sidepanel-content {
      right: 0 !important;
    }
  }
}
.sidepanel-overlay {
  &:after {
    content:'';
    display: block;
    height: 100%;
    width: 100%;
  }
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  z-index: 1;
  @include transition-property(height);
  @include transition-duration(0.01s);
  @include transition-delay(0.5s);
}
.sidepanel-content {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 2;
  background-color: $sidebar_bg;
  overflow: auto;
  @include transition-property(all);
  @include transition-duration(0.5s);
  color: $sidebar_text_primary;
  .unimportant {
    color: darken($sidebar_text_primary, 40);
  }
  .Bt {
    border-top: 1px solid $sidebar_border;
  }
  .sb-header {
    height: 100px;
    line-height: 100px;
    text-transform: uppercase;
    padding:0 50px;
    color: $sidebar_text_secondary;
    font-size: 12px;
    margin:0;
    position:absolute;
    top:0;
    left:0;
    background-color: $sidebar_bg;
    z-index: 1;
    width: 100%;
  }
  .sb-content {
    font-size:$default_font_size + 1;
    padding-top: 100px;
    padding-bottom: 100px;
    height: 100%;
  }
  .sb-footer {
    position: absolute;
    bottom:0;
    left: 0;
    height: 100px;
    width:100%;
    background-color: $sidebar_bg;
  }
  .apollo-list > li {
    &:hover {
      background-color: $sidebar_bg_secondary;
    }
    &>div {
      padding:30px 50px;
    }
  }
}
.sidepanel-open {
  overflow: hidden;
}
