@import './react-select/default';
@import './react-telephone-input/styles';
@import './react-datetime/index';
@import './react-toggle/style';


.input-info {
  font-size: 11px;
  margin-top: 5px;
}
.validation-error {
  margin-top: 5px;
  color: rgb(230, 0, 0);
  display: block;
  text-align: center;
  font-size: 11px;
}
/*
  START - input icons

  font awesome icons inside inputs, like so:

  <div className="left-inner-addon">
    <i className="fa fa-search"></i>
    <input type="text" className="form-control" placeholder="Search..." />
  </div>

*/
.left-inner-addon {
  position: relative;
}
.left-inner-addon input {
  padding-left: 35px;
}
.left-inner-addon .fa {
  position: absolute;
  padding: 23px 15px;
  pointer-events: none;
  z-index: 1;
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-right: 35px;
}
.right-inner-addon .fa {
  right: 0;
  position: absolute;
  padding: 15px;
  pointer-events: none;
  z-index: 1;
}
.input-wrapper {
  position: relative;
  .fa {
    position: absolute;
    z-index: 2;
    color: #ccc;
    font-size: 23px;
    top: 12px;
    left: 12px;
    &.fa-lock {
      left: 15px;
    }
  }
  input {
    position: relative;
    z-index: 1;
  }
  &.has-icon input {
    padding-left: 45px;
  }
}
/* END - input icons */
