// OVERRIDE BOOTSTRAP STYLES IN HERE

.nav-tabs > li > a {
  font-size: 16px;
  background-color: #f6f6f6;
  border-color: #eaeaea #eaeaea #ddd;
  color: $text_color;
  margin-right: 7px;
}
.has-success .form-control,
.has-success .input-group-addon {
  border-color: #9acc9b;
}
.has-success .input-group-addon {
  background-color: #d2ecc7;
  color: #568856;
}
.has-error .input-group-addon {
  background-color: #ffe4e4;
  color: #d46866;
}
.has-error .form-control,
.has-error .input-group-addon {
  border-color: #e6b3b1;
}

body .close {
  font-size: 40px;
  font-weight: 400;
  line-height: 0.5;
}

.btn-group.open .dropdown-toggle {
  @include box-shadow(none);
}
.form-theme-lite .form-control {
  color: #555;
  border: 0 none;
  border: 1px solid lighten($border_color_secondary, 20);
  background-color: #fff;
  @include placeholder-color(darken($text_color, 50));
  &:focus {
    background-color: #f9f9f9;
    border: 1px solid lighten($border_color_secondary, 10);
  }
}
.form-control,
.form-control[disabled]{
  background-color: transparent;
  color: $text_color_bold;
  font-weight: 500;
  font-size: 12px;
  height: 45px;
  padding-left: 18px;
  padding-right: 18px;
  border: 0 none;
  border: 1px solid $input_border_color;
  //@include box-shadow(#fff 0 0 0);
  @include box-shadow(none);
  @include border-radius(0);
  @include placeholder-color($text_color);
  &.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    //border-radius: 3px;
  }
  &:focus {
    color: $text_color_bold;
    background-color: $input_focused_bg_color;
    box-shadow: none !important;
    border-color: $border_color_secondary;
  }
}
.form-control[disabled] {
  color: darken($text_color, 33);
}
.has-error .form-control,
.has-error .Select div.Select-control,
.has-error .dropdown-toggle {
  border-color: $error_color;
  box-shadow: none !important;
  &:focus {
    outline: none !important;
    border-bottom: 1px solid $error_color;
  }
}
.form-group {
  margin-bottom: 22px;
  label {
    font-size: 12px;
    text-transform: uppercase;
  }
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-content {
  color: #000;
  @include border-radius(0);
}

.btn-sml {
  padding: 7px 13px;
}

.tooltip.in {
  opacity: 1;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: $volkno_red;
  top: -2px;
  margin-left: -10px;
  border-width: 0 10px 10px;
}
.tooltip-inner {
  background-color: $volkno_red;
  color: #fff;
  font-size: 14px;
  padding: 5px 12px;
}
