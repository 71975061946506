$default_font_size: 15px;
html {
  box-sizing: border-box;
}

html, body {
  height:100%;
}
body {
  background-color: $primary_bg;
  color: $text_color;
  font-family: $font_opensans;
  letter-spacing: 0.5px;
  font-size: 14px;
}
a, a:hover, a:active, a:visited, .link {
  color: $link_color;
  cursor: pointer;
  outline: none !important;
}
.link:hover, a:hover {
  text-decoration: underline;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  outline: none;
}
.message-bar {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 10001;
  @include transition-property(left);
  @include transition-duration(0.5s);
  &.sticky {
    z-index: 20000 !important;
  }
}
.help-txt {
  color: lighten($text_color, 24);
  font-size:13px;
  padding-left: 15px;
}
.biline {
  font-size: 18px;
}
.error-txt {
  color: $text_color_error;
  font-size:13px;
  padding-left: 15px;
}
.notify {
  background-color: $volkno_red;
  color: #fff;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  top: -2px;
  right: -9px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  @include border-radius(10px);
}
.biline {
  font-size: 16px;
}
@media (max-width: $screen-sm-max) {
  .help-txt {
    display: block;
    margin:5px 0;
    padding-left: 0;
  }
  .error-txt {
    display: block;
    margin:5px 0;
    padding-left: 0;
  }
}
.sidepanel-open .message-bar,
.modal-open .message-bar {
  top: 0 !important;
  z-index: 20010;
  position: fixed;
}
.content-area {
  min-height: 72%;
}
.wrapper > div {
  height: 100%;
}
img.constrain-sml {
  min-width: 50px;
  max-width: 100px;
  width: 100%;
  height: auto;
}
.page-margin {
  margin: 50px;
}
.tag {
  background-color: #e0e0e0;
  border: 1px solid shade(#e0e0e0, 20);
  color: $text_color_dark;
  cursor: pointer;
  padding: 11px 15px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 700;
  @include border-radius(3px);
  @include transition(all 0.3s);
  &.selected {
    background-color: $secondary_color;
    border-color: #8e8e8e;
  }
}
.fullscreen {
  .hide-fullscreen {
    display: none;
  }
}
.user-pic {
  background-color: #d6d6d6;
  border: 2px solid #fff;
  font-size: 72px;
  height: 72px;
  line-height: 93px;
  overflow: hidden;
  text-align: center;
  width: 72px;
  @include border-radius(37px);
}
.show-mobile,
.hide-desktop {
  display: none;
}
.show-desktop,
.hide-mobile {
  display: block;
}

@media (max-width: $screen-sm-max) {
  body#app {
    .mobile-test {
      display: none;
    }
    .mobile-center {
      text-align: center;
    }
    .mobile-left {
      text-align: left;
    }
    .mobile-100 {
      width: 100%;
    }
    .show-mobile,
    .hide-desktop {
      display: block;
    }
    .show-desktop,
    .hide-mobile {
      display: none;
    }
    .mobile-0px-padding {
      padding: 0;
    }
    .mobile-5px-padding {
      padding: 5px;
    }
    .mobile-0px-margin {
      margin: 0;
    }
    .page-margin {
      margin: 10px 0;
    }
    .tag {
      font-size: 10px;
      margin-bottom: 7px;
    }
    .fullscreen {
      .hide-fullscreen-mobile {
        display: none;
      }
    }
  }
}
@media (max-width: $screen-md-max) {
  body#app {
    .tablet-center {
      text-align: center;
    }
  }
}
