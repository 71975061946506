@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
     color: $color;
  }
  &:-moz-placeholder { /* Firefox 18- */
     color: $color;
  }
  &::-moz-placeholder {  /* Firefox 19+ */
     color: $color;
  }
  &:-ms-input-placeholder {
     color: $color;
  }
}
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content; }
  @-moz-keyframes #{$name} { @content; }
  @-ms-keyframes #{$name} { @content; }
  @keyframes #{$name} { @content; }
}

@mixin animation($prop) {
  -webkit-animation: $prop;
  -moz-animation: $prop;
  -o-animation: $prop;
  animation: $prop;
}

@include keyframes(bounce) {
	0%, 20%, 50%, 80%, 100% {
    @include transform(translateY(0));
  }
	40% {
    @include transform(translateY(-22px));
  }
	60% {
    @include transform(translateY(-11px));
  }
}

@mixin animCheckbox($name, $borderWidth, $height, $opacity:0.5) {
  .#{$name} {
  	height:100%;
  	width: 100%;
  	position: relative;
    background-color: rgba(0,0,0, 0);
    @include transition(all);
    @include border-radius(60px);
    &:after {
  		@include transform(scaleX(-1) rotate(180deg + -45deg));
  		@include transform-origin(left, top);
  		content: '';
  		display: block;
  		position: absolute;
      border-right: $borderWidth solid transparent;
    	border-top: $borderWidth solid transparent;
    	height: $height;
      width: $height / 2;
      left: 0;//$height / 2;
      top: 0;//$height;
  	}
  }
  .selected, .checked {
  	.#{$name} {
      background-color: rgba(0,0,0, 0.5);
  		&:after {
  			@include animation(#{$name}-anim 1s);
  			border-color: #fff;
  		}
  	}
  }
  @include keyframes(#{$name}-anim) {
  	0% { height: 0; width: 0; }
  	25% { height: 0; width: $height / 2; }
  	50% { height: $height; width: $height / 2; }
  }
}
@mixin flexCenterContent() {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-flow: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
