h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
h2 {
  font-size: 31px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 700;
}

