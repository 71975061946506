.nav-pills > .nav-item > a.nav-link.active,
.nav-pills > .nav-item > a.nav-link.active:focus,
.nav-pills > .nav-item > a.nav-link.active:hover {
  background-color: $volkno_red;
  color: #fff;
  font-weight: bold;
  @include border-radius(40px);
}
.nav > .nav-item > a.nav-link:focus,
.nav > .nav-item > a.nav-link:hover {
  background-color: lighten($secondary_bg, 5);
  @include border-radius(40px);
}
.nav-pills > .nav-item > a.nav-link {
  color: $text_color;
  font-size: 15px;
  margin-right: 20px;
  padding: 7px 17px;
  text-transform: uppercase;
}
.nav > .nav-item {
  margin-bottom: 10px;
}
.nav-pills {
  margin-top: 35px;
  margin-bottom: 35px;
}
@media (max-width: $screen-sm-max) {
  .nav-pills > .nav-item > a.nav-link {
    font-size: 13px;
    margin-right: 5px;
  }
}
