$sprite-size-w: 2341px;
$sprite-size-h: 136px;

.sprite {
	background-image: url('/images/sprite.png');
	background-size: $sprite-size-w $sprite-size-h;
}

.sprite-angry {
	width: 67px;
	height: 67px;
	background-position: 0 0;
}

.sprite-excited {
	width: 67px;
	height: 67px;
	background-position: 0 -64px;
}

.sprite-check-green {
	width: 50px;
	height: 50px;
	background-position: -67px 0;
}

.sprite-facebook {
	width: 18px;
	height: 27px;
	background-position: -89px -58px;
}

.sprite-cart {
	width: 65px;
	height: 53px;
	background-position: -126px -72px;
}

.sprite-comment {
	width: 38px;
	height: 20px;
	background-position: -117px -9px;
}

.sprite-confused {
	width: 67px;
	height: 67px;
	background-position: -155px 0;
}

.sprite-demand-it {
	width: 36px;
	height: 19px; /* EDITED */
	background-position: -222px -8px; /* EDITED */
}

.sprite-earn {
	width: 113px;
	height: 68px;
	background-position: -258px 0;
}

.sprite-emoji-group {
	width: 174px;
	height: 49px;
	background-position: -371px 0;
}

.sprite-engage {
	width: 106px;
	height: 98px;
	background-position: -545px 0;
}

.sprite-facebook-logo {
	width: 91px;
	height: 36px;
	background-position: -651px 0;
}

.sprite-flame-grey {
	width: 29px;
	height: 18px; /* EDITED */
	background-position: -742px -8px; /* EDITED */
}

.sprite-flame-red {
	width: 42px;
	height: 51px;
	background-position: -771px 0;
}

.sprite-flame-white {
	width: 42px;
	height: 43px;
	background-position: -813px 0;
}

.sprite-funny {
	width: 67px;
	height: 67px;
	background-position: -855px 0;
}

.sprite-joy {
	width: 67px;
	height: 67px;
	background-position: -922px 0;
}

.sprite-like {
	width: 67px;
	height: 67px;
	background-position: -989px 0;
}

.sprite-love {
	width: 67px;
	height: 67px;
	background-position: -1056px 0;
}

.sprite-omg {
	width: 67px;
	height: 67px;
	background-position: -1123px 0;
}

.sprite-ouch {
	width: 67px;
	height: 67px;
	background-position: -1190px 0;
}

.sprite-play-circle {
	width: 85px;
	height: 85px;
	background-position: -1257px 0;
}

.sprite-sad {
	width: 67px;
	height: 67px;
	background-position: -1342px 0;
}

.sprite-save {
	width: 30px;
	height: 30px;
	background-position: -1409px 0;
}

.sprite-tag-it {
	width: 39px;
	height: 20px;
	background-position: -1439px -9px;
}

$volkno-logo-red-width: 548px;
$volkno-logo-red-height: 136px;
$volkno-logo-red-x-pos: -1478px;
$volkno-logo-small-factor: 2.8;
.sprite-volkno-logo-red-big {
	width: 548px;
	height: 136px;
	background-position: -1478px 0;
}
.sprite-volkno-logo-red {
	width: $volkno-logo-red-width / $volkno-logo-small-factor;
	height: $volkno-logo-red-height / $volkno-logo-small-factor;
	background-position: ($volkno-logo-red-x-pos / $volkno-logo-small-factor) 0;
  background-size: ($sprite-size-w / $volkno-logo-small-factor) ($sprite-size-h / $volkno-logo-small-factor);
}

.sprite-volkno-logo-white {
	width: 128px;
	height: 42px;
	background-position: -2026px 0;
}

.sprite-watch {
	width: 105px;
	height: 77px;
	background-position: -2154px 0;
}

.sprite-x-red {
	width: 41px;
	height: 41px;
	background-position: -2259px 0;
}

.sprite-x-white {
	width: 41px;
	height: 41px;
	background-position: -2300px 0;
}


.lp-icons {
	background-image: url('/images/icons/lp-icons.png');
}

@media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
	.lp-icons {
		 background-image: url('/images/icons/lp-icons-2x.png');
		 background-size: 67px 281px;
	}
}

.lp-ico-facebook {
	width: 15px;
	height: 15px;
	background-position: 0 0;
}

.lp-ico-giftcard {
	width: 41px;
	height: 29px;
	background-position: 0 -15px;
}

.lp-ico-feedback {
	width: 30px;
	height: 33px;
	background-position: 0 -44px;
}

.lp-ico-film {
	width: 48px;
	height: 45px;
	background-position: 0 -77px;
}

.lp-ico-game {
	width: 67px;
	height: 33px;
	background-position: 0 -122px;
}

.lp-ico-hide {
	width: 35px;
	height: 23px;
	background-position: 0 -155px;
}

.lp-ico-show {
	width: 35px;
	height: 21px;
	background-position: 0 -178px;
}

.lp-ico-tv {
	width: 51px;
	height: 50px;
	background-position: 0 -199px;
}

.lp-ico-videos {
	width: 32px;
	height: 32px;
	background-position: 0 -249px;
}