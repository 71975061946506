.expand-container {
  position: relative;
  overflow: hidden;
  max-height: 120px;
  padding-bottom: 40px;
  @include transition(max-height 0.5s);
  &.opened {
    max-height: 300px;
  }
}
.expand-cta {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding:5px 0 0;
  @include transition(all 0.4s);
  background-color: #F6F6F6;
}
.toggle-box-lrg.selected .expand-cta,
.toggle-box-lrg:hover .expand-cta {
  background-color: #FFF;
}
