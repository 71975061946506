/*
Format: .[AbbreviatedPropertyName]-[abbreviatedPropertValue] {

}
*/
#app {
  .Fw-b {
    font-weight: bold;
  }
  .Fw-n {
    font-weight: normal;
  }
  .Fs-i {
    font-style: italic;
  }
  .Ww-bw {
    word-wrap: break-word;
    word-break: break-all;
  }
  .Bg-3 {
    background-color: $tertiary_bg;
  }
  .Bt {
    border-top: 1px solid $border_color_primary;
  }
  .Bb {
    border-bottom: 1px solid $border_color_primary;
  }
  .Ta-c {
    text-align: center;
  }
  .Tt-c {
    text-transform: capitalize;
  }
  .Td-u {
    text-decoration: underline;
  }
  .Mx-a {
    margin: 0 auto;
  }
  .M-0 {
    margin: 0;
  }
  .My-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .My-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .My-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .My-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .My-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .Mx-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .Mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .Mx-50 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .My-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .My-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .My-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .Mb-5 {
    margin-bottom: 5px;
  }
  .Mb-10 {
    margin-bottom: 10px;
  }
  .Mb-15 {
    margin-bottom: 15px;
  }
  .Mb-20 {
    margin-bottom: 20px;
  }
  .Mb-30 {
    margin-bottom: 30px;
  }
  .Mb-40 {
    margin-bottom: 40px;
  }
  .Mb-50 {
    margin-bottom: 50px;
  }
  .Mb-60 {
    margin-bottom: 60px;
  }
  .Mt-10 {
    margin-top: 10px;
  }
  .Mt-15 {
    margin-top: 15px;
  }
  .Mt-20 {
    margin-top: 20px;
  }
  .Mt-30 {
    margin-top: 30px;
  }
  .Mr-7 {
    margin-right: 7px;
  }
  .Mr-10 {
    margin-right: 10px;
  }
  .Mr-15 {
    margin-right: 15px;
  }
  .Mr-20 {
    margin-right: 20px;
  }
  .Ml-5 {
    margin-left: 5px;
  }
  .Ml-10 {
    margin-left: 10px;
  }
  .Ml-15 {
    margin-left: 15px;
  }
  .Ml-20 {
    margin-left: 20px;
  }
  .Ml-30 {
    margin-left: 30px;
  }
  .M-20 {
    margin: 20px;
  }
  .H-100 {
    height: 100%;
  }
  .H-a {
    height: auto;
  }
  .W-5 {
    width: 5%;
  }
  .W-10 {
    width: 10%;
  }
  .W-15 {
    width: 15%;
  }
  .W-20 {
    width: 20%;
  }
  .W-25 {
    width: 25%;
  }
  .W-30 {
    width: 30%;
  }
  .W-40 {
    width: 40%;
  }
  .W-50 {
    width: 50%;
  }
  .W-55 {
    width: 55%;
  }
  .W-60 {
    width: 60%;
  }
  .W-70 {
    width: 70%;
  }
  .W-75 {
    width: 75%;
  }
  .W-80 {
    width: 80%;
  }
  .W-90 {
    width: 90%;
  }
  .W-100 {
    width: 100%;
  }
  .Pos-r {
    position: relative;
  }
  .Pos-a {
    position: absolute;
  }
  .T-0 {
    top: 0;
  }
  .B-0 {
    bottom: 0;
  }
  .L-0 {
    left: 0;
  }
  .R-0 {
    right: 0;
  }
  .P-0 {
    padding: 0;
  }
  .P-10 {
    padding: 10px;
  }
  .P-15 {
    padding: 15px;
  }
  .P-20 {
    padding: 20px;
  }
  .P-30 {
    padding: 30px;
  }
  .Px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .Px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .Px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .Px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .Px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .Px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .Px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .Px-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .Py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .Py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .Py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .Py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .Py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .Py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .Pt-5 {
    padding-top: 5px;
  }
  .Pt-10 {
    padding-top: 10px;
  }
  .Pt-15 {
    padding-top: 15px;
  }
  .Pt-20 {
    padding-top: 20px;
  }
  .Pt-30 {
    padding-top: 30px;
  }
  .Pr-5p {
    padding-right: 5%;
  }
  .Pr-5 {
    padding-right: 5px;
  }
  .Pl-5 {
    padding-left: 5px;
  }
  .Pl-10 {
    padding-left: 10px;
  }
  .Pl-15 {
    padding-left: 15px;
  }
  .Pl-20 {
    padding-left: 20px;
  }
  .Pl-40 {
    padding-left: 40px;
  }
  .Pr-10 {
    padding-right: 10px;
  }
  .Pr-15 {
    padding-right: 15px;
  }
  .Pr-20 {
    padding-right: 20px;
  }
  .Pb-10 {
    padding-bottom: 10px;
  }
  .Pb-20 {
    padding-bottom: 20px;
  }
  .Pb-40 {
    padding-bottom: 40px;
  }
  .D-ib {
    display: inline-block;
  }
  .D-b {
    display: block;
  }
  .D-n {
    display: none;
  }
  .Va-t {
    vertical-align: top;
  }
  .Va-m {
    vertical-align: middle;
  }
  .O-h {
    overflow: hidden;
  }
  .O-a {
    overflow: auto;
  }
  .Fz-10 {
    font-size: 10px;
  }
  .Fz-11 {
    font-size: 11px;
  }
  .Fz-13 {
    font-size: 13px;
  }
  .Fz-15 {
    font-size: 15px;
  }
  .Fz-16 {
    font-size: 16px;
  }
  .Fz-18 {
    font-size: 18px;
  }
  .Fz-20 {
    font-size: 20px;
  }
  .Fz-22 {
    font-size: 22px;
  }
  .Fz-26 {
    font-size: 26px;
  }
  .Fz-50 {
    font-size: 50px;
  }
  .F-r {
    float: right;
  }
  .F-l {
    float: left;
  }
  .Ta-l {
    text-align: left;
  }
  .Ta-r {
    text-align: right;
  }
  .C-fff {
    color: #fff;
  }
  .C-777 {
    color: #777;
  }
  .C-000 {
    color: #000;
  }
  .C-grey {
    color: #b9b4b5;
  }
  .Cur-p {
    cursor: pointer;
  }
  .V-v {
    visibility: visible;
  }
  .V-h {
    visibility: hidden;
  }
  .D-t {
    display: table;
  }
  .D-tr {
    display: table-row;
  }
  .D-tc {
    display: table-cell;
  }
  .Z-1 {
    z-index: 1;
  }
  .Z-2 {
    z-index: 2;
  }
  .Z-3 {
    z-index: 3;
  }
  .Ws-nw {
    white-space: nowrap;
  }
  .Ws-n {
    white-space: normal;
  }
  .Lh-40 {
    line-height: 40px;
  }
  .Montserrat {
    font-family: 'montserrat';
    font-weight: 400;
  }
  .Montserrat-bold {
    font-family: 'montserrat';
    font-weight: 700;
  }
  .Opensans {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
  .Opensans-xbold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
  }
  .Georgia {
    font-family: Georgia, Times, 'Times New Roman', serif;
  }
}
