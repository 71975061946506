$login_bg_color: #fff;

.login-view {
  padding-top: 5%;
}
.login-tabs {
  background-color: #eee;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-basis: 1;

  a {
    display: inline-block;
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    color: #969696;
    font-weight: 500;
    padding-top: 26px;
    padding-bottom: 20px;
    text-decoration: none;
    &.selected {
      color: #000;
      border-bottom: 3px solid $volkno_red;
    }
  }
}

.login-box {
  background-color: $login_bg_color;
  color: #000;
  text-align: left;
  float: right;
  width: 388px;
  overflow: hidden;
  @include border-radius(2px);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  @include border-radius(2px);
  .login-container {
    padding: 24px 24px 40px;
  }
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }
  .bottom-links {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 0px;
  }
  h1 {
    font-size: 19px;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 15px;
  }
  .reset-request-sent {
    text-align: center;
    margin-bottom: 30px;
  }
}
