.has-error .react-tel-input{
  border: 1px solid red;
}
div.react-tel-input {
  border: 1px solid $border_color_secondary;
  width: 100%;
  @include border-radius(2px);
  .country-list {
    z-index: 20;
  }
  .flag-dropdown {
    @include border-radius(2px 0 0 2px);
    border: none;
    border-right:1px solid #E4E4E4;
  }
  ul.country-list {
    top: 44px;
  }
  .selected-flag {
    @include border-radius(0);
    height: 100%;
  }
  input[type=tel]:focus,
  input[type=text]:focus {
    border: none;
  }
}
.react-tel-input {
    position: relative;
    width: 200px;
    .ad {
        background-position: -16px 0;
    }
    .ae {
        background-position: -32px 0;
    }
    .af {
        background-position: -48px 0;
    }
    .ag {
        background-position: -64px 0;
    }
    .ai {
        background-position: -80px 0;
    }
    .al {
        background-position: -96px 0;
    }
    .am {
        background-position: -112px 0;
    }
    .ao {
        background-position: -128px 0;
    }
    .ar {
        background-position: -144px 0;
    }
    .as {
        background-position: -160px 0;
    }
    .at {
        background-position: -176px 0;
    }
    .au {
        background-position: -192px 0;
    }
    .aw {
        background-position: -208px 0;
    }
    .az {
        background-position: -224px 0;
    }
    .ba {
        background-position: -240px 0;
    }
    .bb {
        background-position: 0 -11px;
    }
    .bd {
        background-position: -16px -11px;
    }
    .be {
        background-position: -32px -11px;
    }
    .bf {
        background-position: -48px -11px;
    }
    .bg {
        background-position: -64px -11px;
    }
    .bh {
        background-position: -80px -11px;
    }
    .bi {
        background-position: -96px -11px;
    }
    .bj {
        background-position: -112px -11px;
    }
    .bm {
        background-position: -128px -11px;
    }
    .bn {
        background-position: -144px -11px;
    }
    .bo {
        background-position: -160px -11px;
    }
    .br {
        background-position: -176px -11px;
    }
    .bs {
        background-position: -192px -11px;
    }
    .bt {
        background-position: -208px -11px;
    }
    .bw {
        background-position: -224px -11px;
    }
    .by {
        background-position: -240px -11px;
    }
    .bz {
        background-position: 0 -22px;
    }
    .ca {
        background-position: -16px -22px;
    }
    .cd {
        background-position: -32px -22px;
    }
    .cf {
        background-position: -48px -22px;
    }
    .cg {
        background-position: -64px -22px;
    }
    .ch {
        background-position: -80px -22px;
    }
    .ci {
        background-position: -96px -22px;
    }
    .ck {
        background-position: -112px -22px;
    }
    .cl {
        background-position: -128px -22px;
    }
    .cm {
        background-position: -144px -22px;
    }
    .cn {
        background-position: -160px -22px;
    }
    .co {
        background-position: -176px -22px;
    }
    .cr {
        background-position: -192px -22px;
    }
    .cu {
        background-position: -208px -22px;
    }
    .cv {
        background-position: -224px -22px;
    }
    .cw {
        background-position: -240px -22px;
    }
    .cy {
        background-position: 0 -33px;
    }
    .cz {
        background-position: -16px -33px;
    }
    .de {
        background-position: -32px -33px;
    }
    .dj {
        background-position: -48px -33px;
    }
    .dk {
        background-position: -64px -33px;
    }
    .dm {
        background-position: -80px -33px;
    }
    .do {
        background-position: -96px -33px;
    }
    .dz {
        background-position: -112px -33px;
    }
    .ec {
        background-position: -128px -33px;
    }
    .ee {
        background-position: -144px -33px;
    }
    .eg {
        background-position: -160px -33px;
    }
    .er {
        background-position: -176px -33px;
    }
    .es {
        background-position: -192px -33px;
    }
    .et {
        background-position: -208px -33px;
    }
    .fi {
        background-position: -224px -33px;
    }
    .fj {
        background-position: -240px -33px;
    }
    .fk {
        background-position: 0 -44px;
    }
    .fm {
        background-position: -16px -44px;
    }
    .fo {
        background-position: -32px -44px;
    }
    .fr, .bl, .mf {
        background-position: -48px -44px;
    }
    .ga {
        background-position: -64px -44px;
    }
    .gb {
        background-position: -80px -44px;
    }
    .gd {
        background-position: -96px -44px;
    }
    .ge {
        background-position: -112px -44px;
    }
    .gf {
        background-position: -128px -44px;
    }
    .gh {
        background-position: -144px -44px;
    }
    .gi {
        background-position: -160px -44px;
    }
    .gl {
        background-position: -176px -44px;
    }
    .gm {
        background-position: -192px -44px;
    }
    .gn {
        background-position: -208px -44px;
    }
    .gp {
        background-position: -224px -44px;
    }
    .gq {
        background-position: -240px -44px;
    }
    .gr {
        background-position: 0 -55px;
    }
    .gt {
        background-position: -16px -55px;
    }
    .gu {
        background-position: -32px -55px;
    }
    .gw {
        background-position: -48px -55px;
    }
    .gy {
        background-position: -64px -55px;
    }
    .hk {
        background-position: -80px -55px;
    }
    .hn {
        background-position: -96px -55px;
    }
    .hr {
        background-position: -112px -55px;
    }
    .ht {
        background-position: -128px -55px;
    }
    .hu {
        background-position: -144px -55px;
    }
    .id {
        background-position: -160px -55px;
    }
    .ie {
        background-position: -176px -55px;
    }
    .il {
        background-position: -192px -55px;
    }
    .in {
        background-position: -208px -55px;
    }
    .io {
        background-position: -224px -55px;
    }
    .iq {
        background-position: -240px -55px;
    }
    .ir {
        background-position: 0 -66px;
    }
    .is {
        background-position: -16px -66px;
    }
    .it {
        background-position: -32px -66px;
    }
    .jm {
        background-position: -48px -66px;
    }
    .jo {
        background-position: -64px -66px;
    }
    .jp {
        background-position: -80px -66px;
    }
    .ke {
        background-position: -96px -66px;
    }
    .kg {
        background-position: -112px -66px;
    }
    .kh {
        background-position: -128px -66px;
    }
    .ki {
        background-position: -144px -66px;
    }
    .km {
        background-position: -160px -66px;
    }
    .kn {
        background-position: -176px -66px;
    }
    .kp {
        background-position: -192px -66px;
    }
    .kr {
        background-position: -208px -66px;
    }
    .kw {
        background-position: -224px -66px;
    }
    .ky {
        background-position: -240px -66px;
    }
    .kz {
        background-position: 0 -77px;
    }
    .la {
        background-position: -16px -77px;
    }
    .lb {
        background-position: -32px -77px;
    }
    .lc {
        background-position: -48px -77px;
    }
    .li {
        background-position: -64px -77px;
    }
    .lk {
        background-position: -80px -77px;
    }
    .lr {
        background-position: -96px -77px;
    }
    .ls {
        background-position: -112px -77px;
    }
    .lt {
        background-position: -128px -77px;
    }
    .lu {
        background-position: -144px -77px;
    }
    .lv {
        background-position: -160px -77px;
    }
    .ly {
        background-position: -176px -77px;
    }
    .ma {
        background-position: -192px -77px;
    }
    .mc {
        background-position: -208px -77px;
    }
    .md {
        background-position: -224px -77px;
    }
    .me {
        background-position: -112px -154px;
        height: 12px;
    }
    .mg {
        background-position: 0 -88px;
    }
    .mh {
        background-position: -16px -88px;
    }
    .mk {
        background-position: -32px -88px;
    }
    .ml {
        background-position: -48px -88px;
    }
    .mm {
        background-position: -64px -88px;
    }
    .mn {
        background-position: -80px -88px;
    }
    .mo {
        background-position: -96px -88px;
    }
    .mp {
        background-position: -112px -88px;
    }
    .mq {
        background-position: -128px -88px;
    }
    .mr {
        background-position: -144px -88px;
    }
    .ms {
        background-position: -160px -88px;
    }
    .mt {
        background-position: -176px -88px;
    }
    .mu {
        background-position: -192px -88px;
    }
    .mv {
        background-position: -208px -88px;
    }
    .mw {
        background-position: -224px -88px;
    }
    .mx {
        background-position: -240px -88px;
    }
    .my {
        background-position: 0 -99px;
    }
    .mz {
        background-position: -16px -99px;
    }
    .na {
        background-position: -32px -99px;
    }
    .nc {
        background-position: -48px -99px;
    }
    .ne {
        background-position: -64px -99px;
    }
    .nf {
        background-position: -80px -99px;
    }
    .ng {
        background-position: -96px -99px;
    }
    .ni {
        background-position: -112px -99px;
    }
    .nl, .bq {
        background-position: -128px -99px;
    }
    .no {
        background-position: -144px -99px;
    }
    .np {
        background-position: -160px -99px;
    }
    .nr {
        background-position: -176px -99px;
    }
    .nu {
        background-position: -192px -99px;
    }
    .nz {
        background-position: -208px -99px;
    }
    .om {
        background-position: -224px -99px;
    }
    .pa {
        background-position: -240px -99px;
    }
    .pe {
        background-position: 0 -110px;
    }
    .pf {
        background-position: -16px -110px;
    }
    .pg {
        background-position: -32px -110px;
    }
    .ph {
        background-position: -48px -110px;
    }
    .pk {
        background-position: -64px -110px;
    }
    .pl {
        background-position: -80px -110px;
    }
    .pm {
        background-position: -96px -110px;
    }
    .pr {
        background-position: -112px -110px;
    }
    .ps {
        background-position: -128px -110px;
    }
    .pt {
        background-position: -144px -110px;
    }
    .pw {
        background-position: -160px -110px;
    }
    .py {
        background-position: -176px -110px;
    }
    .qa {
        background-position: -192px -110px;
    }
    .re {
        background-position: -208px -110px;
    }
    .ro {
        background-position: -224px -110px;
    }
    .rs {
        background-position: -240px -110px;
    }
    .ru {
        background-position: 0 -121px;
    }
    .rw {
        background-position: -16px -121px;
    }
    .sa {
        background-position: -32px -121px;
    }
    .sb {
        background-position: -48px -121px;
    }
    .sc {
        background-position: -64px -121px;
    }
    .sd {
        background-position: -80px -121px;
    }
    .se {
        background-position: -96px -121px;
    }
    .sg {
        background-position: -112px -121px;
    }
    .sh {
        background-position: -128px -121px;
    }
    .si {
        background-position: -144px -121px;
    }
    .sk {
        background-position: -160px -121px;
    }
    .sl {
        background-position: -176px -121px;
    }
    .sm {
        background-position: -192px -121px;
    }
    .sn {
        background-position: -208px -121px;
    }
    .so {
        background-position: -224px -121px;
    }
    .sr {
        background-position: -240px -121px;
    }
    .ss {
        background-position: 0 -132px;
    }
    .st {
        background-position: -16px -132px;
    }
    .sv {
        background-position: -32px -132px;
    }
    .sx {
        background-position: -48px -132px;
    }
    .sy {
        background-position: -64px -132px;
    }
    .sz {
        background-position: -80px -132px;
    }
    .tc {
        background-position: -96px -132px;
    }
    .td {
        background-position: -112px -132px;
    }
    .tg {
        background-position: -128px -132px;
    }
    .th {
        background-position: -144px -132px;
    }
    .tj {
        background-position: -160px -132px;
    }
    .tk {
        background-position: -176px -132px;
    }
    .tl {
        background-position: -192px -132px;
    }
    .tm {
        background-position: -208px -132px;
    }
    .tn {
        background-position: -224px -132px;
    }
    .to {
        background-position: -240px -132px;
    }
    .tr {
        background-position: 0 -143px;
    }
    .tt {
        background-position: -16px -143px;
    }
    .tv {
        background-position: -32px -143px;
    }
    .tw {
        background-position: -48px -143px;
    }
    .tz {
        background-position: -64px -143px;
    }
    .ua {
        background-position: -80px -143px;
    }
    .ug {
        background-position: -96px -143px;
    }
    .us {
        background-position: -112px -143px;
    }
    .uy {
        background-position: -128px -143px;
    }
    .uz {
        background-position: -144px -143px;
    }
    .va {
        background-position: -160px -143px;
    }
    .vc {
        background-position: -176px -143px;
    }
    .ve {
        background-position: -192px -143px;
    }
    .vg {
        background-position: -208px -143px;
    }
    .vi {
        background-position: -224px -143px;
    }
    .vn {
        background-position: -240px -143px;
    }
    .vu {
        background-position: 0 -154px;
    }
    .wf {
        background-position: -16px -154px;
    }
    .ws {
        background-position: -32px -154px;
    }
    .ye {
        background-position: -48px -154px;
    }
    .za {
        background-position: -64px -154px;
    }
    .zm {
        background-position: -80px -154px;
    }
    .zw {
        background-position: -96px -154px;
    }
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .hide {
        display: none;
    }
    .v-hide {
        visibility: hidden;
    }
    input[type=text],
    input[type=tel] {
        position: relative;
        z-index: 0;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 44px;
        margin-left: 0;
        background: $input_bg_color;
        border: none;
        line-height: 25px;
        font-size:12px;
        height: 43px;
        width: 100%;
        font-weight: 500;
        color: #424A5B;
        @include border-radius(2px);
        @include box-shadow(#fff 0 0 0);
        @include placeholder-color(#949ba1);
        &:focus {
            border-color: #42bdff;
            border-left-color: #cacaca;
        }
        &.invalid-number {
            border: 1px solid #d79f9f;
            background-color: #FAF0F0;
            border-left-color: #cacaca;
            &:focus {
                border: 1px solid #d79f9f;
                border-left-color: #cacaca;
                background-color: #FAF0F0;
            }
        }
    }
    .flag-dropdown {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0;
        background-color: #eaeaea;
        border: 1px solid #cacaca;
        border-radius: 3px 0 0 3px;
        &.open-dropdown {
            background: #fff;
            border-bottom: 0;
            border-radius: 3px 0 0 0;
            .selected-flag {
                background: #fff;
                border-radius: 3px 0 0 0;
            }
        }
        &:hover {
            cursor: pointer;
            .selected-flag {
                background-color: #fff;
            }
        }
    }
    input[disabled] {
        &+.flag-dropdown {
            &:hover {
                cursor: default;
                .selected-flag {
                    background-color: transparent;
                }
            }
        }
    }
    .selected-flag {
        z-index: 13;
        position: relative;
        width: 38px;
        height: 26px;
        padding: 0 0 0 8px;
        border-radius: 3px 0 0 3px;
        .flag {
            position: absolute;
            top: 50%;
            margin-top: -5px;
        }
        .arrow {
            position: relative;
            top: 50%;
            margin-top: -2px;
            left: 20px;
            width: 0;
            height: 0;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 4px solid #555;
            &.up {
                border-top: none;
                border-bottom: 4px solid #555;
            }
        }
    }
    .country-list {
        list-style: none;
        position: absolute;
        z-index: 2;
        padding: 0;
        margin: -1px 0 0 -1px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
        background-color: white;
        border: 1px solid #ccc;
        width: 400px;
        max-height: 200px;
        overflow-y: scroll;
        border-radius: 0 0 3px 3px;
        top: 26px;
        .flag {
            display: inline-block;
        }
        .divider {
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid #ccc;
        }
        .country {
            padding: 5px 10px;
            .dial-code {
                color: #999;
            }
            &:hover {
                background-color: #e8f7fe;
            }
            &.highlight {
                background-color: #c7e2f1;
            }
        }
        .flag {
            margin-right: 6px;
            margin-top: 2px;
        }
        .country-name {
            margin-right: 6px;
        }
    }
}
