.btn {
  outline: none !important;
  position: relative;
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-weight: bold;
  padding: 10px 35px;
  @include border-radius(2px);
  @include transition(all 0.5s);
}
@media (max-width: $screen-sm-max) {
  .btn {
    font-size: 14px;
    padding: 10px 26px;
  }
}
// .btn[disabled], .btn.disabled {
//   opacity: 1;
// }
.btn-primary,
.btn-primary:visited,
.btn-primary:active,
.btn-primary:hover {
  color: #fff;
  background-color: $quaternary_bg;
  border: 0px none;
  &:hover {
    background-color: #15ad50;
  }
  &:focus {
    background-color: $quaternary_bg;
  }
  &[disabled],
  &.disabled {
    color: #77dc9e;
  }
}

.btn-default {
  border-color: $border_color_secondary;
}


.btn-default.focus,
.btn-default:focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-text,
.btn-text:visited,
.btn-text:active,
.btn-text:hover {
  border: 0px none;
  background-color: transparent;
}

.btn-info,
.btn-info:visited,
.btn-info:active,
.btn-info:hover {
  color: $primary_color;
  border: 1px solid $primary_color;
  background-color: #000;
  @include border-radius(0);
}

.btn-primary.red {
  background-color: $volkno_red;
  color: #fff;
  &:hover {
    background-color: lighten($volkno_red, 7);
  }
  &[disabled] {
    background-color: #545454;
    color: #a0a0a0;
  }
}

.btn-link {
  color: #000;
  text-decoration: none;
}

.btn.btn-facebook {
  background-color: #3c69b4;
  color: #fff;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-transform: none;
  width: 50%;
  margin: 0 auto;
  display: block;
  padding: 5px 0;
  @include border-radius(100px);
  .lp-ico-facebook {
    left: -10px;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
}
