
.Select-white {
  .Select-control {
    background-color: #fff !important;
    border-color: #999;
    border: 1px solid #999;
    color: #000;
  }
  .Select-value-label {
    color: #000;
  }
  .Select-menu-outer {
    background-color: #fff !important;
    border: 1px solid #999;
    border-top-color: #444;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }
  .Select-option {
    color: #000;
  }
  .Select-option.is-focused {
    background-color: #eee !important;
    color: #000 !important;
  }
}