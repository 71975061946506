$primary_color: #fff;
$secondary_color: #fff;
$primary_bg: #000;
$secondary_bg: #171717;
$tertiary_bg: #252525;
$quaternary_bg: #139b48;
$quinary_bg: #f0faff;
$text_color: #b9b4b5;
$text_color_bold: #fff;
$text_color_dark: #000;
$text_color_error: red;
$link_color: $text_color;
$error_color: #a20000;
$border_color_primary: $tertiary_bg;
$border_color_secondary: #808080;
$input_bg_color: $primary_bg;
$input_focused_bg_color: lighten($primary_bg, 5);
$input_border_color: #232323;
$volkno_red: #cb2128;
